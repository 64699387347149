img {
  max-width: 100%;
  height: auto;
}

.logo {
  display: block;
  margin: 0 auto;
}

#image-grid {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transform-origin: bottom right;

  &.active {
    opacity: 1;
  }
}

.image-unit {
  position: absolute;
}

.image-item {
  max-width: 25vw;
  height: auto;
}

.image-item-caption {
  max-width: 75%;
  font-size: 10px;
  word-break: break-all;
  opacity: 0.15;
}
