.navigation {
  text-transform: uppercase;
  position: fixed;
  display: flex;
  z-index: 3;
  top: 0;
  width: 100%;
  justify-content: center;

  ul {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;

    li {
      flex-grow: 1;
      max-width: 33.3333333%;
    }
  }
}

.navigation-item {
  display: flex;
  padding: 2em 0 1em;
  font-size: 0.5em;
  justify-content: center;

  &.xed:after {
    height: 2px;
    width: 50%;
    top: 2.7em;
    left: 25%;

    @media (max-width: $small-vp) {
      width: 60%;
      left: 20%;
    }
  }
}

.first {
  justify-content: flex-end;
  padding-right: 1em;
}

.last {
  justify-content: flex-start;
  padding-left: 1em;
}
