button,
.btn,
[type="button"],
[type="reset"],
[type="submit"] {
  color: $black;
  cursor: pointer;
  background: $white;
  border: 0;
  border-radius: 0;
  box-shadow: inset 0 0 0 0.4vw $black;
  -webkit-appearance: none;
  flex-shrink: 0;
  position: relative;

  &:hover {
    color: $white;
    background: $black;
  }

  @media (max-width: $small-vp) {
    display: block;
    text-align: center;
  }
}

.form-item {
  padding: 0.85em 1em 1em;

  &:focus {
    position: relative;
    z-index: 1;
    outline-width: 0.4vw;
    outline-style: dashed;
    outline-color: $black;
    outline-offset: 1vw;
  }
}
