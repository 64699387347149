@mixin outline-text($color, $moz-fallback: $color) {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $color;

  @media (max-width: $small-vp) {
    -webkit-text-stroke-width: .5px;
  }

  @supports not ((-webkit-text-stroke: 1px $color)) {
    color: $moz-fallback;
    text-shadow: 1px 1px 0 $color, -1px -1px 0 $color, 1px -1px 0 $color,
            -1px 1px 0 $color;
  }
}

.inline-outline {
  @include outline-text($black, $white);
  color: transparent;
}

[data-link-text]:hover {
  .inline-outline {
    display: none;
  }
}

.xed {
  &:after {
    content: '';
    display: block;
    width: calc(100% + 60px);
    left: -30px;
    top: calc(50% - 0.2vw);
    height: 0.4vw;
    background: $text-color;
    position: absolute;

    @media (max-width: $small-vp) {
      width: calc(100% + 30px);
      height: 0.5vw;
      left: -15px;
    }
  }
}
