.header {
  -webkit-font-smoothing: subpixel-antialiased;
  min-height: calc(100vh + 10px);
  margin-bottom: 3%;
  background: $accent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20vh 0 10vh;
  color: transparent;

  &.fixed::after {
    z-index: 1;
  }

  &:after {
    position: fixed;
    height: 10px;
    width: 100%;
    content: '';
    bottom: -10px;
    top: 0;
    display: block;
    background: $accent;
    z-index: -1;
  }

  .heading-one {
    @include outline-text($black, $accent);
    position: relative;
    z-index: 2;
  }

  @media (max-width: $small-vp) {
    min-height: 87vh;
    padding: 10vh 0 5vh;
  }
}

.speak-unit-header {
  line-height: 1.2em;
}

.speakers-container {
  margin-top: 3.5em;

  .content-container + & {
    margin-top: 2.75em;
  }
}

.credits {
  margin-top: 3em;

  p {
    margin: 1.5em 0;
  }
}

// Footnotes

.footnotes {
  padding: 10% 0;
  margin-top: 8%;
  color: $white;
  background: $accent;
  position: relative;
  z-index: 2;

  a {
    color: $white;
  }
}

#manifesto {
  position: absolute;
  top: 0;
}

// 404 Page

.page-notfound {
  -webkit-font-smoothing: subpixel-antialiased;
  background: $accent;
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  padding-left: 8.5%;
  padding-right: 8.5%;
}