// Opentype Mixin

@mixin ot-features {
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "alt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

// Normalized Font Weight
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  @include ot-features;
  margin: 0;
  font-weight: normal;
}

sup {
  font-size: 50%;
  color: $black;
  @include outline-text(transparent, $text-color);
}

.solid {
  color: $text-color;
  @include outline-text(transparent, $text-color);
}

.heading-one {
  font-size: 1.5em;
  line-height: 1.3em;
  color: transparent;
  @include outline-text($black, $white);

  & + & {
    margin-top: 1.5em;
  }
}

.heading-two {
  font-size: 1em;
  line-height: 1.2em;

  &.sponsors {
    font-size: 0.5em;
  }

  @media (max-width: $small-vp) {
    font-size: 1.4em;
  }
}

.heading-container {
  .section-title:first-child {
    margin-bottom: 0;
  }
}

.speaker-unit {
  margin-bottom: 1em;

  @media (min-width : $small-vp) and (max-width : 1024px) {
    margin-bottom: 1.7em;
  }


  @media (max-width: $small-vp) {
    margin-bottom: 2em;
  }

  p {
    font-size: 0.87em;
    line-height: 1.45em;

    @media (min-width : $small-vp) and (max-width : 1024px) {
      font-size: 1.3em;
    }

    a {
      text-decoration: underline;
      display: inline-block;

      @media (max-width: $small-vp) {
        display: inline;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.content-three {
  font-size: 0.5em;
  line-height: 1.3em;

  .content-header {
    margin-top: 2em;
    margin-bottom: 0.5em;

    & + p {
      margin-top: 0.5em;
    }
  }

  @media (max-width: $small-vp) {
    font-size: 1em;
  }
}

.speaker-unit-content {
  max-width: 61.5vw;

  @media (min-width : $small-vp) and (max-width : 1024px) {
    max-width: none;
  }

  @media (max-width: $small-vp) {
    max-width: none;
  }
}

.section-title {
  margin-bottom: 1em;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2em;

  @media (max-width: $small-vp) {
    letter-spacing: 0;
  }
}
