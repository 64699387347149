// Font Family
$font-family: 'PxGroteskRegular', 'Helvetica Neue', Helvetica, sans-serif;

// Color
$black: #000;
$white: #fff;
$text-color: #000;

$accent: #54bb8c;

// Breakpoints
$small-vp: 568px;
$smallest-vp: 320px;
