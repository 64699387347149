html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-family;
  font-size: 3vw;

  @media (max-width: $small-vp) {
    font-size: 1em;
  }
}

.container {
  margin-right: 8.5%;
  margin-left: 8.5%;
}

.apply-container {
  margin-top: 4em;
  margin-bottom: 4em;
  text-align: center;
}

p {
  @include ot-features;
}

ul {
  padding: 0;
  margin-top: 0;
  list-style: none;
}

.horizontal {
  display: flex;
  margin-top: 0.5em;

  @media (max-width: $small-vp) {
    flex-wrap: wrap;
  }
}

// Selection
::-moz-selection {
  color: $white;
  text-shadow: none;
  background: $black;
}

::selection {
  color: $white;
  text-shadow: none;
  background: $black;
}

// Links

a {
  color: $text-color;
  text-decoration: none;
  @include outline-text(transparent, $text-color);


  &:active {
    position: relative;
    z-index: 1;
    outline-width: 0.1em;
    outline-style: dashed;
    outline-color: $text-color;
    outline-offset: 0.1em;
  }
}

[data-link-text] {
  position: relative;
  display: inline-block;

  &:hover {
    color: transparent;
    transform: none;

    &::before {
      display: block;
    }
  }

  &::before {
    position: absolute;
    display: none;
    color: $text-color;
    content: attr(data-link-text);
    transform: skew(45deg);
    transform-origin: center center;
  }

  @media (max-width: $small-vp) {
    display: inline;

    &:hover {
      color: $text-color;

      &::before {
        display: none;
      }
    }
  }
}

.website {
  @media (max-width: $small-vp) {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.footnotes [data-link-text]::before {
  color: $white;
}
